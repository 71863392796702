body {
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
  font-size: 14px;
}

#calendar {
  max-width: 900px;
  margin: 0 auto;
}
/* .fc-day-grid-event {
  background: white !important;
  border: none !important;

}  */
.fc-event {
  transition: color .2s ease, border-color .2s ease, opacity .6s ease, box-shadow .2s ease;
  border: none;
  border-left: 2px solid #939393;
  padding: 3px
px
;
  background-color: white;
  border-radius: 3px;
  color: #333;
  margin: 1px 0;
  box-shadow: 0 1px 2px rgb(0 0 0 / 7%);
  cursor: pointer;
  margin-bottom: 2px;
  opacity: 1;
}

.fc-list {
  width: 100%;
}
.fc-list-event {
  width: 70%;
}
.fc-icon {
  width: 30px;
  height: 30px;
}

